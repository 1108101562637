@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  width: 13px;
}

body::-webkit-scrollbar-track {
  background: #ffffff98;
}

body::-webkit-scrollbar-thumb {
  background-color: #d1d1d1b7;
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  body::-webkit-scrollbar {
    display: none;
  }
}

html, body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior:smooth
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list {
  list-style: disc;
  margin: 16px 0;
  padding: 0 0 0 40px;
}

.contactUsForm input {
  outline: linear-gradient(90deg, #3707FF 32.54%, #F14668 95.12%) !important;
}

.contactUsFormButton {
  margin-top: 50px;
  width: 137px;
  height: 44px;
  background: linear-gradient(90deg, #3707FF 32.54%, #F14668 95.12%) !important;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF ; 
  box-shadow: 10px 4px 26px rgba(0, 0, 0, 0.25);
}

#about{
  scroll-margin-top: 205px;
}

#benefits{
  scroll-margin-top: 130px;
}

#us{
  scroll-margin-top: 175px;
}

#services{
  scroll-margin-top: 100px;
}