.navBarItem {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #F5F5F5;
}

.navBarItemContact {
  font-size: 14px !important;
  width: 152px !important;
  height: 45.91px !important;
  color: #333333 !important;
  background: #F5F5F5 !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  filter: drop-shadow(10px 4px 26px rgba(0, 0, 0, 0.25));
}

.navBar header {
  max-width: 100vw !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.homeBigParagraph {
  width: 33.3%;
  font-weight: 700 !important;
  line-height: 55px;
  color: #142594;
}

.goToEMSMethodButton {
  margin-top: 25px;
  width: 260px !important;
  height: 44px !important;
  background: linear-gradient(90deg, #3707FF 37.85%, #83CFE3 95.12%) !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #FFFFFF !important; 
  box-shadow: 10px 4px 26px 0 rgba(0, 0, 0, 0.25);
}

.bg-gradient-diagonal {
  background: linear-gradient(to top right, rgba(113, 186, 205, 0.5), rgba(27, 29, 181, 0.5));
}